.missing-docs {
  width: calc(100% + 16px);
  padding: 8px 8px 4px;
  border-radius: 5px;
  border: 2px solid rgba(248, 71, 71, 0.31);
  background: rgba(255, 205, 205, 0.33);
  margin: 3px -8px 6px;
}

.missing-docs__title {
  font-size: 11px;
  font-weight: bold;
  color: #F84747;
  margin-bottom: 4px;
}

