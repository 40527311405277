.login-google-button {
  display: flex;
  position: relative;
  background: linear-gradient(90deg,rgb(148,26,189),rgb(246,31,126),rgb(254,164,120));
  border: none;
  border-radius: 4px;
  width: 280px;
  height: 55px;
  align-items: center;
  padding-left: 13px;
  cursor: pointer
}

.login-google-button:hover:before {
  background: transparent;
}

.login-google-button:before {
  content: "";
  border-radius: 2px;
  background: white;
  position: absolute;
  bottom: 3px;
  left: 3px;
  right: 3px;
  top: 3px;
  z-index: 0;
  transition: .3s;
}

.login-google-button:hover:after {
  background: white;
  background-clip: text;
  -webkit-background-clip: text;
}

.login-google-button:after {
  position: relative;
  background: linear-gradient(90deg,rgb(148,26,189),rgb(246,31,126),rgb(254,164,120));
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  content: attr(data-content);
  font-size: .875rem;
  transition: .2s;
  z-index: 2;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  font-weight: bold;
}

.login-google-button img {
  width: 30px;
  height: 30px;
  z-index: 3;
  position: relative;
  justify-self: flex-start;
  margin-right: 8px;
  padding: 5px;
  background: white;
  border-radius: 1000px;
}
