html, #root {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  overscroll-behavior: none;
  /*overscroll-behavior-y: contain;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  position: fixed;
  width: 100vw;
}

.bold-red {
  font-weight: bold;
  color: red;
}

/* The sentry bug report button */
#sentry-feedback {
  --bottom: 90px;
  --right: auto;
  --top: auto;
  --left: 1rem;
}


/* Safari 11+ */
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {

  /*!* Fixes weird flexbox safari bug *!*/
  body div {
    /*min-height: fit-content;*/
    flex-shrink: 0;
    flex-basis: auto;
  }

}}
